import Keycloak from 'keycloak-js';
import { fetchUtils } from 'ra-core';

export const initOptions = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM || 'digiprod',
    url:
        process.env.REACT_APP_KEYCLOAK_URL ||
        'https://keycloak.kube.diginavis.net',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'admin-back',
};

export const keycloak = Keycloak(initOptions);

export const onKeycloakEvent = (event: string) => {
    if (event === 'onTokenExpired') {
        keycloak.updateToken(30).catch(() => {
            console.log('Failed to refresh token!');
        });
    }
};

// for data provider, it writes token to an authorization header
export const httpClientWithAuth = (
    url: string,
    options: fetchUtils.Options = {}
) => {
    const headers = options?.headers
        ? new Headers(options.headers)
        : new Headers({ Accept: 'application/json' });

    if (keycloak.token) {
        headers.set('Authorization2', 'Bearer ' + keycloak.token);
    }
    options.headers = headers;
    return fetchUtils.fetchJson(url, options);
};
